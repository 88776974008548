import axios from "axios"

const BASE_URL = "https://contact-forms.cldware.com/api"

const postRequest = async (path, data) => {
  try {
    return await axios.post(`${BASE_URL}/${path}`, data, {
      headers: { "Content-Type": "application/json" },
    })
  } catch (err) {
    throw err
  }
}

export const submitNewsletterForm = async data => {
  const path = "newsletter/cloudware"
  await postRequest(path, data)
}

export const submitPOSSubscriptionForm = async data => {
  const path = "subscribe-pos/cloudware"
  await postRequest(path, data)
}

export const submitPOSContactForm = async data => {
  const path = "contact-pos/cloudware"
  await postRequest(path, data)
}
