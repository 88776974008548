import React from "react"
import { Link } from "gatsby"

const TermsAndConditions = () => (
  <p>
    Eu li e aceito os
    <Link to="/termos-e-condicoes">termos, condições</Link>,
    <Link to="/politica-privacidade">política de privacidade</Link>e
    <Link to="/politica-campanhas">
      informações de campanhas
    </Link>
  </p>
)

export default TermsAndConditions
