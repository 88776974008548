import React from "react"

const DistrictList = React.forwardRef(({ styles, name, error, style }, ref) => (
  <div
    className={` ${error ? styles.inputError : ""} ${styles.selectContainer}`}
  >
    <img
      className={styles.dropdownIcon}
      src={require("../../../img/dropdown.png")}
      alt=""
    />
    <select
      style={style}
      name={name}
      ref={ref}
      className={error ? styles.inputError : ""}
      placeholder="Distrito"
    >
      <option value="" disabled>
        Distrito
      </option>

      <option value="Aveiro">Aveiro</option>
      <option value="Beja">Beja</option>
      <option value="Braga">Braga</option>
      <option value="Bragança">Bragança</option>
      <option value="Castelo Branco">Castelo Branco</option>
      <option value="Coimbra">Coimbra</option>
      <option value="Évora">Évora</option>
      <option value="Faro">Faro</option>
      <option value="Guarda">Guarda</option>
      <option value="Leiria">Leiria</option>
      <option value="Lisboa">Lisboa</option>
      <option value="Portalegre">Portalegre</option>
      <option value="Porto">Porto</option>
      <option value="Santarém">Santarém</option>
      <option value="Setubal">Setubal</option>
      <option value="Viana do Castelo">Viana do Castelo</option>
      <option value="Vila Real">Vila Real</option>
      <option value="Viseu">Viseu</option>
      <option value="Madeira">Madeira</option>
      <option value="Açores">Açores</option>
    </select>
  </div>
))

export default DistrictList
